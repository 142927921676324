import React, { ReactNode, useEffect, useRef } from 'react'
import * as styles from './blockWith2Cols.module.scss'
import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import { useInView } from 'react-intersection-observer'
import { useColorCombination } from '../../../hooks/useColorCombination'

if (typeof window !== 'undefined') {
  gsap.registerPlugin(ScrollTrigger)
}

export interface BlockWith2ColsProps {
  id?: string
  colorCombination: string
  reverse?: boolean
  withLink?: boolean
  bgContent?: ReactNode
  mainContent?: ReactNode
  className?: string
  uniqId?: string
}

function BlockWith2Cols(props: BlockWith2ColsProps) {
  const {
    id,
    reverse,
    colorCombination = 'navy',
    bgContent,
    mainContent,
    className = '',
    uniqId,
  } = props
  const sectionRef = useRef(null)
  const bigImgWrapRef = useRef(null)
  const { ref, inView } = useInView({
    triggerOnce: true,
    rootMargin: '-200px 0px',
  })
  const combinationColor = useColorCombination(colorCombination)

  // useEffect(() => {
  //   gsap.fromTo(
  //     `.${uniqId ? uniqId : 'section-lifestyle'} .image-animate`,
  //     {
  //       scale: 1.2,
  //       translateY: -80,
  //     },
  //     {
  //       scale: 1,
  //       translateY: 0,
  //       scrollTrigger: {
  //         trigger: sectionRef.current,
  //         start: 'top 70%',
  //         end: 'bottom bottom',
  //         scrub: true,
  //       },
  //     }
  //   )
  // }, [])

  return (
    <section
      ref={ref}
      id={id}
      style={
        {
          '--main-bg': combinationColor.mainBg,
          '--inner-bg': combinationColor.innerBg,
        } as React.CSSProperties
      }
    >
      <div
        className={`section-lifestyle ${uniqId} ${styles.lifestyle} ${
          styles[reverse ? 'lifestyleReverse' : '']
        } ${className} ${inView ? 'in-view' : ''}`}
      >
        <div
          className={`${styles.lifestyle__content} block-2-content`}
          ref={sectionRef}
        >
          <div className={styles.lifestyle__bg}></div>
          <div className={`${styles.lifestyle__desc} block-2-bg-content`}>
            {bgContent}
          </div>
        </div>
        <div className={styles.lifestyle__img} ref={bigImgWrapRef}>
          {mainContent}
        </div>
      </div>
    </section>
  )
}

export default BlockWith2Cols
