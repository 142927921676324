import { graphql } from 'gatsby'
import React, { useEffect, useState } from 'react'
import Layout from '../components/layout'
import Hero from '../components/Shared/Hero'
import Event from '../components/Shared/Event'
import Summer from '../components/Shared/Summer'
import Logement from '../components/Shared/Logement'
import Push from '../components/Shared/Push'
import Questionnaires from '../components/Shared/Questionnaires'
import * as styles from './styles/residence.module.scss'
import * as stylesStay from './styles/single-stay.module.scss'
import { Helmet } from 'react-helmet'
import Instagram from '../components/Shared/Instagram'
import DecoBigIllus from '../components/Illus/DecoBigIllus'
import { useLocation } from '@reach/router'
import BlockExperience from '../components/Shared/BlockExperience'
import BlockStep from '../components/Shared/BlockStep'
import Deal from '../components/Shared/Deal'
import ServicesStay from '../components/Shared/ServiceStay'
import BlockService from '../components/Shared/BlockService'
import BlockServiceSpace from '../components/Shared/BlockServiceSpace'
import BlockSeoText from '../components/Shared/BlockSeoText'
import BlockIntro from '../components/Shared/BlockIntro'
import Lifestyle from '../components/Shared/Lifestyle'
import * as stylesPages from './styles/single-page.module.scss'
import Breadcrumb from '../components/Core/Breadcrumb'
import { head } from '../components/head'
import usePageView from '../hooks/usePageView'
import Modal from '../components/Core/Modal'
import ModalGeneral from '../components/Shared/ModalGeneralContent'
import useLinkLocalized from '../hooks/useLinkLocalized'
import { useTranslation } from '../hooks/useTranslation'
import slugify from 'slugify'

export const Head = head('umbracoSinglePage')

export default function DefaultPage(props: any) {
  const { data } = props
  const page = data.umbracoSinglePage
  const listingPage = data.umbracoListingResidencePage
  // const residenceBlog = data.umbracoResidenceBlogPost
  const popinGeneralVisible = page.popinGeneralVisible
  const instagramData = data.umbracoInstagram
  const pageTitle = page?.metaTitle
    ? page?.metaTitle
    : page?.title
    ? page?.title.replace(/\n/g, '')
    : 'Stay'
  const loc = useLocation()
  const location = useLocation()
  const [showGeneral, setShowGeneral] = useState<boolean>(false)
  const { t } = useTranslation('blog')
  const [cityNameFromUrl, setCityNameFromUrl] = useState('')
  const [cityNameUrl, setCityNameUrl] = useState('')
  const [singleResidenceUrl, setSingleResidenceUrl] = useState('')
  const [singleResidenceNameFromUrl, setSingleResidenceNameFromUrl] = useState('')

  const cleanUrl = (url: String) => {
    return url.replace(/^\/|\/$/g, '');
  }

  const capitalizeWords = (str: String) => {
    const resultString = str.replace(/-/g, ' ');
    return resultString
      .toLowerCase()
      .split(' ')
      .map(word => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ');
  }

  useEffect(() => {
    window.scrollTo(0, 0)
    const baseName = location.pathname
      .replace(slugify(page.name, { lower: true }), '')
      .split('/')[2]
    const splittedURl = cleanUrl(location.pathname).split('/')
    if (baseName) {
      const cityUrl = splittedURl.slice(0, 2).join('/');
      setCityNameUrl(`/${cityUrl}`)
      setCityNameFromUrl(capitalizeWords(baseName.replace('-', ' ')))
    }

    //get third part of url
    if(splittedURl.length > 3 ) {
      const singleResidenceName = splittedURl[2]
      const singleResidenceUrl = splittedURl.slice(0, 3).join('/');
      setSingleResidenceUrl(`/${singleResidenceUrl}`)
      setSingleResidenceNameFromUrl(capitalizeWords(singleResidenceName.replaceAll('-', ' ')))
    }
  }, [])

  useEffect(() => {
    window.scrollTo(0, 0)

    if (typeof window !== 'undefined' && window.document) {
      usePageView(page.items, window.location.pathname)
    }

    if (typeof window !== 'undefined' && window.document) {
       if (
            popinGeneralVisible &&
            !sessionStorage.getItem('single_page_' + page.id + '_already_shown')
          ) {
            setTimeout(() => {
              setShowGeneral(true)
            }, 2000)
          }
    }

  }, [])

  const hideModal = () => {
    setShowGeneral(false)
    sessionStorage.setItem('single_page_' + page.id + '_already_shown', 'true')
  }

  return page ? (
    <>
      <Helmet>
        <body className="single-page" />
      </Helmet>
      <script
        type="application/ld+json"
        dangerouslySetInnerHTML={{
          __html: JSON.stringify({
            '@context': 'https://schema.org',
            '@type': 'RealEstateAgent',
            name: page.title,
            image:
              page.imageCover && page.imageCover.childImageSharp
                ? page.imageCover.childImageSharp.gatsbyImageData.images
                    .fallback.src
                : '',
            '@id': page.title,
            url: loc?.href,
            telephone: '',
            address: {
              '@type': 'PostalAddress',
              streetAddress: page.streetAddress,
              addressLocality: page.addressLocality,
              postalCode: page.postalCode,
              addressCountry: page.addressCountry,
            },
            geo: {
              longitude: page.longitude,
              latitude: page.latitude,
            },
          }),
        }}
      ></script>
      <Layout
        className={`${styles.residenceDetail} ${stylesStay.singleStay} ${stylesPages.singlePage}  ${page.hideHeaderAndFooter ? 'single-page-hide-header-footer' : ''}`}
        canDisplayPopin={popinGeneralVisible}
      >
        <Hero
          title={page.title.replace(/\n/g, '<br>')}
          image={page.imageCover}
          image-mobile={page.imageCoverMobile}
          video={page.videoCover}
          isBottom
        >
          <DecoBigIllus className="deco" />
        </Hero>
        {page.items &&
          page.items.map((homeBlock: any, index: number) => {
            if (homeBlock.content) {
              const blockContent = homeBlock.content
              switch (blockContent.contentTypeAlias) {
                case 'blockPageIntro':
                  return (
                    <BlockIntro
                      key={`bli-${index}`}
                      title={blockContent.introTitle}
                      description={blockContent.introSubtitle}
                      cta={blockContent.introCta}
                    />
                  )
                case 'residencePush':
                  return (
                    <Summer
                      data={{
                        title: blockContent.title,
                        paragraph: blockContent.subtitle,
                        link:
                          blockContent.links && blockContent.links[0]
                            ? blockContent.links[0]
                            : {
                                name: 'Contacter la residence',
                                url: '/',
                              },
                        cta: blockContent.virtualVisit
                          ? {
                              title: 'VISITE VISTUELLE',
                              path: '',
                            }
                          : undefined,
                        position: page.title,
                      }}
                      bg={blockContent.backgroundColor}
                      titleColor={blockContent.titleColor}
                      key={`s-${index}`}
                      className="summer-push"
                    />
                  )
                case 'blockService':
                  return (
                    <BlockService data={blockContent} key={`home-${index}`} />
                  )
                case 'blockBookSpace':
                  return (
                    <BlockServiceSpace
                      data={blockContent}
                      key={`home-${index}`}
                    />
                  )
                case 'blockStayIncludedService':
                  return (
                    <ServicesStay data={blockContent} key={`bis-${index}`} />
                  )
                case 'blockConcept':
                  return (
                    <Lifestyle
                      uniqId={`spl-${index}`}
                      data={blockContent}
                      reverse
                      withLink
                      key={`home-${index}`}
                      className={styles.homeLifeStyle}
                    />
                  )
                case 'blockPush':
                  return (
                    <Push
                      data={blockContent}
                      key={`bp-${index}`}
                      position={page.title}
                    />
                  )
                case 'blockEvents':
                  return <Event data={blockContent} key={`be-${index}`} />
                case 'blockFAQ':
                  return (
                    <Questionnaires data={blockContent} key={`fa-${index}`} />
                  )
                case 'blockExperience':
                  return (
                    <BlockExperience
                      data={blockContent}
                      key={`bex-${index}`}
                      location={page.title}
                    />
                  )
                case 'blockStep':
                  return <BlockStep data={blockContent} key={`bst-${index}`} />
                case 'blockDeal':
                  return <Deal data={blockContent} key={`bd-${index}`} />
                case 'blockSEOText':
                  return <BlockSeoText content={blockContent.description} />
              }
            }

            return null
          })}

        {page.bottomSEOText && <BlockSeoText content={page.bottomSEOText} />}
        {!page.hideHeaderAndFooter && instagramData.enabled && <Instagram data={instagramData} />}

        <Breadcrumb
          data={[
            {
              title: listingPage?.title || t('Listing residence', 'residence'),
              link: useLinkLocalized({ url: listingPage?._url }),
              id: '' + useLinkLocalized({ url: listingPage?._url }),
            },
            {
              title: cityNameFromUrl,
              link: cityNameUrl,
              id: cityNameUrl,
            },
            ...(singleResidenceNameFromUrl !== ''
              ? [{
                  title: singleResidenceNameFromUrl,
                  link: singleResidenceUrl,
                  id: singleResidenceUrl,
                }]
              : []),
            {
              title: page.title,
              link: location.pathname,
              id: '' + location.pathname,
            },
          ]}
        />

        <div id="modal-single-page">
          { popinGeneralVisible && page.popinToDisplay && (
            <Modal
              data={page.popinToDisplay}
              visible={showGeneral}
              onClose={hideModal}
              width={'1018px'}
              secondaryBg="FFFFFF"
              size="lg"
              general
              darkClose
            >
              <ModalGeneral data={page.popinToDisplay} />
            </Modal>
          )}
        </div>

      </Layout>
    </>
  ) : (
    <></>
  )
}

export const query = graphql`
  query ($name: String, $lang: String) {
    umbracoListingResidencePage(lang: { eq: $lang }) {
      _url
      title
    }
    umbracoResidenceBlogPost(name: { eq: $name }, lang: { eq: $lang }) {
      name
      metaTitle
      metaDescription
      metaFollow
      metaIndex
      twitterCard {
        id
        url
      }
      facebookCard {
        id
        url
      }
      _url
      _urls {
        fr
        en_us
      }
      title
      chapeau
      imageCover {
        childImageSharp {
          id
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
      imageCoverMobile {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
      videoCover {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
      articleBody
      _createDate
      _updateDate
      shortTitle
      category {
        name
        title
        color
      }
      city {
        title
        name
        _url
      }
      # blockPush {
      #   name
      #   title
      #   backgroundColor
      #   title
      #   subtitle
      #   link {
      #     url
      #     type
      #     name
      #   }
      #   illustration {
      #     src
      #     gatsbyImageData(width: 200, quality: 90)
      #   }
      # }
      relatedArticles {
        _id
        name
        introduction
        title
        shortTitle
        articleBody
        category {
          color
          title
        }
      }
    }
    umbracoSinglePage(name: { eq: $name }, lang: { eq: $lang }) {
      id
      pageIdentifier
      name
      metaTitle
      metaDescription
      metaFollow
      metaIndex
      bottomSEOText
      hideHeaderAndFooter
      twitterCard {
        id
        url
      }
      facebookCard {
        id
        url
      }
      title
      _url
      _urls {
        fr
        en_us
      }
      imageCover {
        childImageSharp {
          id
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
      imageCoverMobile {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
      videoCover {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
      popinGeneralVisible
      popinToDisplay {
        _id
        name
        popinType
        subtitle
        stayTitle
        staySubtitle
        isActive
        popinGTMEventName
        cta {
          name
          target
          type
          url
        }
        _urls {
          en_us
          fr
        }
        colorCombination
        contactPhoneNumber
        contentTypeAlias
        title
        _url
      }
      items {
        content {
          contentTypeAlias
          introTitle
          introSubtitle
          introCta {
            name
            url
            type
            target
          }
          title
          subtitle
          layout
          colorCombination
          link {
            url
            name
            type
          }
          button {
            name
            type
            url
          }
          slides {
            content {
              legend
              image {
                src
                gatsbyImageData
              }
              imageMobile {
                src
                gatsbyImageData
              }
              backgroundVimeoId
              contentTypeAlias
            }
          }
          description
          links {
            url
            name
            type
          }
          stepContent
          cover {
            src
            gatsbyImageData(layout: CONSTRAINED, width: 1480, quality: 90)
          }
          illustration {
            src
          }
          experienceLink {
            name
            url
            type
            target
          }
          experienceLinkEvent
          stepLink {
            name
            url
            target
          }
          stepLinkEvent
          shortDescription
          experienceItems
          experienceItemssecond
          secondShortDescription
          thirdShortDescription
          authorName
          authorPosition
          reversePlacement
          textColor
          backgroundColor
          backgroundVimeoId
          linkBookSpace {
            name
            type
            url
          }
          itemsPush {
            name
            title
            subtitle
            sortOrder
            titleColor
            backgroundColor
            contentTypeAlias
            listItem
            link {
              url
              type
              name
              target
            }
            linkEvent
            illustration {
              src
            }
            pushStyle
          }
          spacesList {
            content {
              title
              subtitle
              linkSpaceItem {
                name
                url
                type
              }
              thumbnail {
                src
                gatsbyImageData(width: 1480, quality: 90)
              }
            }
          }
          dealsItems {
            thumbnail {
              src
              gatsbyImageData(width: 880, quality: 90)
            }
            backgroundVimeoId
            title
            description
            link {
              url
              name
            }
          }
        }
      }
    }
    umbracoInstagram(lang: { eq: $lang }) {
      title
      hashtag
      instagramHandle
      instagramLinkButton {
        url
        name
        type
      }
      enabled
    }
    allPageIntl {
      edges {
        node {
          name
          uri
          alternates {
            name
            uri
            lang
          }
        }
      }
    }
  }
`
