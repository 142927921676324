import * as styles from './serviceSpace.module.scss'
import { GatsbyImage, IGatsbyImageData } from 'gatsby-plugin-image'
import React from 'react'
import Heading from '../../Core/Badge/Heading'
import Text from '../../Core/Text'
import KLink from '../../Core/KLink'
import replaceHife from '../../../utils/replaceHife'

export interface BlockServiceSpaceItemProps {
  content: {
    thumbnail: {
      src: string
      gatsbyImageData: IGatsbyImageData
    }
    mewsId: string
    subtitle: string
    title: string
    linkSpaceItem: {
      url: string
      name: string
    }
  }
}

export default function BlockServiceSpaceItem(
  props: BlockServiceSpaceItemProps
) {
  const {
    content: { thumbnail, subtitle, title, linkSpaceItem },
  } = props
  return (
    <div className={styles.spaceItem}>
      <div className={styles.spaceHeading}>
        { thumbnail && thumbnail.gatsbyImageData ? (
          <GatsbyImage image={thumbnail.gatsbyImageData} alt={title} />
        ) : thumbnail && thumbnail.src ? (
          <img src={thumbnail?.src} alt={title} loading="lazy" />
        ) : undefined}
        <Heading tag="h2">
          <span dangerouslySetInnerHTML={{ __html: replaceHife(title) }}></span>
        </Heading>
      </div>
      <Text
        tag="p"
        as="bodyH2"
        dangerouslySetInnerHTML={{ __html: replaceHife(subtitle) }}
      ></Text>
      {linkSpaceItem && (
        <KLink to={linkSpaceItem.url} title="" label={linkSpaceItem.name}  type={linkSpaceItem.url.includes('http') ? 'EXTERNAL' : linkSpaceItem.type}/>
      )}
    </div>
  )
}
