// extracted by mini-css-extract-plugin
export var event = "event-module--event--31916";
export var eventCategory = "event-module--eventCategory--66f96";
export var eventCategoryWrap = "event-module--eventCategoryWrap--4921c";
export var eventIllustration = "event-module--eventIllustration--b5bf2";
export var eventList = "event-module--eventList--ea7d8";
export var eventTitleWrap = "event-module--eventTitleWrap--64deb";
export var eventWrap = "event-module--eventWrap--4bce2";
export var event__content = "event-module--event__content--5e5e6";
export var event__header = "event-module--event__header--56ed5";
export var event__title = "event-module--event__title--8c6fd";
export var isOpen = "event-module--isOpen--16ef1";