import { GatsbyImage } from 'gatsby-plugin-image'
import React from 'react'
import useBreakpoint from '../../../hooks/useBreakpoint'
import Slider from '../../Core/Slider'
import Text from '../../Core/Text'
import VideoProvider from '../../Core/VideoProvider'

export interface LifeStyleSliderItem {
  content: {
    image: {
      src?: string
      media: {
        alt: string
      }
      gatsbyImageData: any
    }
    backgroundVimeoId?: string
    legend: string
  }
}

interface LifeStyleSliderProps {
  slides: LifeStyleSliderItem[]
}

export default function LifeStyleSlider(props: LifeStyleSliderProps) {
  const { slides } = props

  const breakpoint = useBreakpoint()

  return (
    <Slider
      className="bg-white"
      loop={true}
      pagination={false}
      spaceBetween={0}
      speed={500}
      animatedTransition
      changeOnSmallHeight
      data={slides.map((s, index) => ({
        content: (
          <figure key={`s-${index}`} className="relative">
            {s.content.backgroundVimeoId ? (
              <VideoProvider
                source={s.content.backgroundVimeoId ? 'vimeo' : 'upload'}
                className={`c-video-hero`}
                data={{
                  contentUrl: s.content.backgroundVimeoId,
                  embedUrl: s.content.backgroundVimeoId,
                  poster:
                    s.content.image && s.content.image.gatsbyImageData
                      ? s.content.image.gatsbyImageData
                      : null,
                }}
              />
            ) : s.content.image && s.content.image.gatsbyImageData ? (
              <GatsbyImage
                image={{
                  ...s.content.image.gatsbyImageData,
                  width: ['sm'].includes(breakpoint) ? 410 : 953,
                  height: ['sm'].includes(breakpoint) ? 400 : 860,
                }}
                imgClassName={'swiper-lazy'}
                alt={s.content.image?.media?.alt || ''}
              />
            ) : (
              <img
                data-src={s.content.image.src}
                className={`swiper-lazy`}
                alt={s.content.legend || ''}
              />
            )}
            {/* <div className="swiper-lazy-preloader swiper-lazy-preloader-white" /> */}
            <Text tag="span" as="h6" className="categ font-bold text-white">
              {s.content.legend}
            </Text>
          </figure>
        ),
      }))}
    />
  )
}
