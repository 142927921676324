import React from 'react'

export default function ArrowCircle() {
  return (
    <svg
      width="23"
      height="23"
      viewBox="0 0 23 23"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        cx="11.5"
        cy="11.5"
        r="11"
        fill="currentColor"
        stroke="currentColor"
      />
      <path className="link-path" d="M15 8L7 16" stroke="white" />
      <path className="link-path" d="M9 7.5H15.5V14" stroke="white" />
    </svg>
  )
}
