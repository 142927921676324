import React, { useState } from 'react'
import Text from '../../Core/Text'
import * as styles from './event.module.scss'

import image from '../../../images/event.png'
import { GatsbyImage } from 'gatsby-plugin-image'
import { useTranslation } from '../../../hooks/useTranslation'
import replaceHife from '../../../utils/replaceHife'
import VideoProvider from '../../Core/VideoProvider'

export interface EventItemProps {
  event: EventProps
}

export interface EventProps {
  bg?: string
  title: string
  subtitle: string
  thumbnail: {
    gatsbyImageData: any
    media: {
      _url: string
      alt: string
    }
  }
  backgroundVimeoId?: string
  price: number
  category: {
    title: string
  }
}

export default function EventItem(props: EventProps) {
  // const { event } = props
  const eventPrice = false;
  const [isOpen, setIsOpen] = useState(false)
  const {
    bg = '212345',
    title,
    subtitle,
    category,
    thumbnail,
    price,
    backgroundVimeoId,
  } = props
  const { t } = useTranslation('common')

  const mobileOpen = () => {
    if (window.innerWidth < 1024) {
      setIsOpen(!isOpen)
    }
  }

  return (
    <div className={styles.event}>
      <div
        className={`${styles.event__content} ${isOpen ? styles.isOpen : ''}`}
        style={{ '--bg-event': `#${bg}` } as React.CSSProperties}
        onClick={mobileOpen}
      >
        <div className={styles.event__header}>
          <Text
            tag="p"
            as="h4"
            className={styles.event__title}
            dangerouslySetInnerHTML={{ __html: replaceHife(title) }}
          ></Text>
          <Text
            tag="p"
            as="bodyH2"
            className="text-white"
            dangerouslySetInnerHTML={{ __html: replaceHife(subtitle) }}
          ></Text>
          {eventPrice && eventPrice !== 0 ? (
            <Text tag="p" as="bodyH2" className="mt-6">
              {eventPrice} {t('/ mois', 'common')}
            </Text>
          ) : price && price !== 0 ? (
            <Text tag="p" as="bodyH2" className="mt-6">
              {t('À partir de', 'common')} {price}
              {t('/ mois', 'common')}
            </Text>
          ) : null}
        </div>
        {category && category.title && (
          <Text tag="span" as="captionH1" className="font-bold">
            {category.title}
          </Text>
        )}
        {!isOpen && (
          <Text tag="span" as="bodyH2" className="mt-2 lg:hidden">
            {t('Voir le détail', 'common')}
          </Text>
        )}
      </div>
      <div className={styles.eventIllustration}>
        {backgroundVimeoId ? (
          <VideoProvider
            source={backgroundVimeoId ? 'vimeo' : 'upload'}
            className={`c-video-hero`}
            data={{
              contentUrl: backgroundVimeoId,
              embedUrl: backgroundVimeoId,
              poster:
                thumbnail && thumbnail.gatsbyImageData
                  ? thumbnail.gatsbyImageData
                  : null,
            }}
          />
        ) : thumbnail && thumbnail.gatsbyImageData ? (
          <GatsbyImage
            image={thumbnail.gatsbyImageData}
            alt={thumbnail?.media?.alt || title}
          />
        ) : (
          <img
            src={thumbnail?.media?._url || image}
            alt={title}
            loading="lazy"
          />
        )}
      </div>
    </div>
  )
}
