import React from 'react'
import replaceHife from '../../../utils/replaceHife'
import Text from '../../Core/Text'
import * as styles from './blockIntro.module.scss'
import KLink from "../../Core/KLink";
import Button from '../../Core/Button'

interface BlockIntroProps {
  title: string
  description: string
  cta?: {
    name: string
    url: string
    target?: string
    type?: string
  }
}

export default function BlockIntro(props: BlockIntroProps) {
  const { title, description, cta } = props
  return (
    <section className={styles.intro}>
      <div className="container">
        <Text tag="h2" as="h2">
          {title}
        </Text>
        <Text
          tag="div"
          as="subtitleH1"
          className={styles.introContent}
          dangerouslySetInnerHTML={{ __html: replaceHife(description) }}
        ></Text>
        {cta && (
            <Button
              tag="link"
              btnType="primary"
              {...(cta.url.includes('http')
                  ? { type: 'EXTERNAL' }
                  : {})}
              to={cta.url}
              type={cta.type}
              target={cta.target}
              label={cta.name}
              className="mt-4"
            >
              {cta.name}
            </Button>
        )}
      </div>
    </section>
  )
}
