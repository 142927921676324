import React, { useEffect, useMemo, useState } from 'react'
import { useInView } from 'react-intersection-observer'
import { useTranslation } from '../../../hooks/useTranslation'
import KLink from '../../Core/KLink'
import Slider from '../../Core/Slider'
import Text from '../../Core/Text'
import Filter from '../../Icon/Filter'
import * as styles from './event.module.scss'
import EventItem, { EventItemProps } from './EventItem'

export interface EventProps {
  data: {
    contentTypeAlias: string
    title: string
    eventsList: EventItemProps[]
    subtitle?: string
    link?: Array<{
      url: string
      name: string
    }>
  }
}

export default function Event(props: EventProps) {
  const { data } = props
  const { title, eventsList: dataEventsList } = data
  const { ref, inView } = useInView({
    triggerOnce: true,
    rootMargin: '-200px 0px',
  })

  const [eventsList, setEventsList] = useState<EventItemProps[]>(dataEventsList)

  const [showFilter, setShowFilter] = useState(false)

  const [swiperSlider, setSwiperSlider] = useState<any>()

  const { t } = useTranslation(['common'])

  const handleShowFilter = (e: React.MouseEvent) => {
    e.preventDefault()
    setShowFilter(!showFilter)
  }

  const handleChooseCategory = (category: string) => {
    if (category === 'all') {
      setEventsList(dataEventsList)
    } else {
      setEventsList(
        dataEventsList.filter((e: any) =>
          e && e.category
            ? e.category.title === category
            : e._links
            ? e._links.category.title === category
            : false
        )
      )
    }
    setShowFilter(false)
  }

  const getUniqCategory = (eventsList: EventItemProps[]) => {
    return eventsList.reduce<string[]>((acc, item: any) => {
      if (
        item &&
        item.category &&
        !acc.includes(item.category.title)
      ) {
        acc = [...acc, item.category.title]
      }

      return acc
    }, [])
  }

  useEffect(() => {
    if (swiperSlider && swiperSlider.autoplay) {
      if (inView) {
        swiperSlider.autoplay.start()
      } else {
        swiperSlider.autoplay.stop()
      }
    }
  }, [inView])

  const onSliderInit = (initializedSlider: any) => {
    if (initializedSlider) setSwiperSlider(initializedSlider)
  }

  useEffect(() => {
    setEventsList(dataEventsList)
    window.addEventListener('click', (e: any) => {
      if (!e.target.closest(`.${styles.eventCategoryWrap}`)) {
        setShowFilter(false)
      }
    })
  }, [])

  return (
    <section
      ref={ref}
      id={data.contentTypeAlias}
      className={`${styles.eventWrap} event-wrap ${inView ? 'in-view' : ''}`}
    >
      <div className="container">
        <div className={styles.eventTitleWrap}>
          <Text
            tag="h2"
            as="h2"
            className="uppercase event-title"
            dangerouslySetInnerHTML={{ __html: title }}
          ></Text>
          {data.subtitle && (
            <Text tag="p" as="subtitleH2" className="mt-4 lg:mt-0" dangerouslySetInnerHTML={{ __html: data.subtitle }}>
            </Text>
          )}
        </div>
        <div
          className={`md:flex mt-4 ${
            data.link && data.link.length > 0
              ? 'justify-between'
              : 'justify-end'
          }`}
        >
          {data.link && data.link.length > 0 && (
            <KLink
              to={data.link[0].url}
              label={data.link[0].name}
              btnType="dark"
            />
          )}
          <div className={styles.eventCategoryWrap}>
            <button
              aria-label={t('Filtrer par catégorie', 'common') as string}
              onClick={handleShowFilter}
              className="mt-3 md:mt-0 flex gap-2.5 items-center"
            >
              <Text tag="span" as="buttonH2">
                {t('Filtrer par catégorie', 'common')}
              </Text>
              <Filter />
            </button>
            {dataEventsList && dataEventsList.length > 0 && showFilter && (
              <div className={styles.eventCategory}>
                <ul>
                  <li onClick={() => handleChooseCategory('all')}>
                    {t('Tous les catégories', 'common')}
                  </li>
                  {getUniqCategory(dataEventsList).map((category, index) => (
                    <li
                      key={`c-${index}`}
                      onClick={() => handleChooseCategory(category)}
                    >
                      {category}
                    </li>
                  ))}
                </ul>
              </div>
            )}
          </div>
        </div>
        <div className={styles.eventList}>
          <Slider
            slidesPerView="auto"
            spaceBetween={16}
            changeOnSmallHeight
            breakpoints={{
              768: {
                slidesPerView: 2,
              },
              1024: {
                slidesPerView: 3,
              },
            }}
            cssMode={true}
            autoplay={{ delay: 5000, pauseOnMouseEnter: true }}
            onInit={onSliderInit}
            pagination={false}
            data={
              eventsList && eventsList.length > 0
                ? eventsList
                    .filter((e) => e != undefined &&  e != null)
                    .map((d, index) => ({
                      content: <EventItem key={`e-${index}`} {...d} />,
                    }))
                : []
            }
          />
        </div>
      </div>
    </section>
  )
}
