import React, { useEffect, useMemo, useState } from 'react'
import { ServiceItem } from '.'
import useBreakpoint from '../../../hooks/useBreakpoint'
import { useTranslation } from '../../../hooks/useTranslation'
import replaceHife from '../../../utils/replaceHife'
import Text from '../../Core/Text'
import * as styles from './services.module.scss'

interface ServiceStayItemProps {
  data: ServiceItem[]
  reload?: number
}

export default function ServiceTab(props: ServiceStayItemProps) {
  const { data, reload } = props

  const [detail, setDetail] = useState<ServiceItem>(data && data[0])
  const [current, setCurrent] = useState<number>(0)

  const breakpoint = useBreakpoint()

  const { t } = useTranslation(['residence'])

  const handleDetail = (item: ServiceItem, index: number) => {
    setDetail(item)
    setCurrent(index)
  }

  const detailBlock = (detail: ServiceItem) =>
      (
        <div className={styles.serviceDetail}>
          <Text tag="div" as="captionH1" className={styles.serviceDetailTitle}>
            {t('Detail du service', 'residence')}
          </Text>
          <Text
            tag="div"
            as="bodyH3"
            className={styles.serviceDetailDesc}
            dangerouslySetInnerHTML={{ __html: replaceHife(detail.subtitle) }}
          ></Text>
        </div>
      )
      
  useEffect(() => {
    setDetail(data && data[0])
    setCurrent(0)
  }, [reload])

  return (
    <div className={styles.servicesList}>
      <ul className="lg:grid grid-cols-2 gap-x-16 md:gap-x-4">
        {data.map((item, index) => (
          <li
            key={`s-${index}`}
            className={current === index ? styles.current : ''}
            onMouseEnter={() =>
              ['lg', 'xl'].includes(breakpoint) && handleDetail(item, index)
            }
            onMouseLeave={() =>
              ['lg', 'xl'].includes(breakpoint) && setCurrent(-1)
            }
          >
            <Text
              tag="p"
              as="h3"
              className={`cursor-pointer ${
                current === index ? styles.active : ''
              }`}
              onClick={() => handleDetail(item, index)}
              dangerouslySetInnerHTML={{ __html: replaceHife(item.title) }}
            ></Text>
            {current === index &&
              !['2xl'].includes(breakpoint) &&
              detailBlock(item)}
          </li>
        ))}
      </ul>
      {detail && ['2xl'].includes(breakpoint) && <div className={styles.serviceDetail}>
          <Text tag="div" as="captionH1" className={styles.serviceDetailTitle}>
           {t('Detail du service', 'residence')}
          </Text>
          <Text
            tag="div"
            as="bodyH3"
            className={styles.serviceDetailDesc}
            dangerouslySetInnerHTML={{ __html: replaceHife(detail.subtitle) }}
          ></Text>
        </div>}
    </div>
  )
}
