import React from 'react'

export default function Filter() {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="9.5" cy="8.5" r="2" stroke="#212345" />
      <circle cx="14.5" cy="16.5" r="2" stroke="#212345" />
      <line x1="4" y1="8.5" x2="8" y2="8.5" stroke="#212345" />
      <line x1="11" y1="8.5" x2="20" y2="8.5" stroke="#212345" />
      <line x1="16" y1="16.5" x2="20" y2="16.5" stroke="#212345" />
      <line x1="4" y1="16.5" x2="13" y2="16.5" stroke="#212345" />
    </svg>
  )
}
