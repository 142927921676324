import React, { ReactNode, useEffect, useRef, useState } from 'react'
import replaceHife from '../../../utils/replaceHife'
import ChevronDown from '../../Icon/ChevronDown'
import Text from '../Text'
import * as styles from './accordion.module.scss'

export interface AccordionItem {
  title: ReactNode
  content?: string
  htmlContent?: any
}

interface AccordionProps {
  data: AccordionItem[]
  onOpen?(index: number): void
  forceClose?: boolean
}

export default function Accordion(props: AccordionProps) {
  const { data, onOpen, forceClose } = props
  const [current, setCurrent] = useState<number>()
  const accordionRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (forceClose) {
      closeAllAccordion()
    }
  }, [forceClose])

  const closeAllAccordion = () => {
    if (accordionRef.current) {
      const $accordionContent = accordionRef.current.querySelectorAll(
        `.${styles.accordionBody}`
      ) as NodeListOf<HTMLElement>

      Array.from($accordionContent).forEach((accordion) => {
        accordion.removeAttribute('style')
      })

      return $accordionContent
    }

    return []
  }

  const handleOpen = (index: number) => {
    setCurrent((oldIndex) => {
      if (accordionRef.current) {
        if (oldIndex !== index) {
          const $accordionContent = closeAllAccordion()
          const currentAccordionContent = $accordionContent[index]
          currentAccordionContent.style.height = `${currentAccordionContent.scrollHeight}px`
          if (onOpen) {
            onOpen(index)
          }
          return index
        } else {
          closeAllAccordion()
          return undefined
        }
      }
    })
  }

  return (
    <div className={styles.accordion} ref={accordionRef}>
      {data.map((d, index) => {
        return (
          d.title &&
          (d.content || d.htmlContent) && (
            <div
              className={`${styles.accordionContent} ${
                index === current ? styles.accordionContentVisible : ''
              } accordion-content`}
              onClick={() => handleOpen(index)}
              key={`a-${index}`}
            >
              <div className={`${styles.accordionHead} accordion-title`}>
                {d.title}
                <ChevronDown />
              </div>
              {d.content && (
                <Text
                  tag="div"
                  as="bodyH2"
                  className={`${styles.accordionBody} accordion-body`}
                  dangerouslySetInnerHTML={{ __html: replaceHife(d.content) }}
                ></Text>
              )}
              {d.htmlContent && (
                <Text
                  tag="div"
                  as="bodyH3"
                  className={`${styles.accordionBody} accordion-body`}
                >
                  {d.htmlContent}
                </Text>
              )}
            </div>
          )
        )
      })}
    </div>
  )
}
